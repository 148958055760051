import Cookies from 'js-cookie';

export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';

export default function userReducer(state, action) {
   switch (action.type) {
      case LOGIN:
         return JSON.parse(action.payload);
      case LOGOUT:
         Cookies.remove('access-token');
         Cookies.remove('refresh-token');
         Cookies.remove('current-user');
         return null;
      default:
         return state;
   }
}
