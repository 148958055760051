import { useEffect, useReducer, useState } from 'react';
import Cookies from 'js-cookie';
import useRouteElements from './useRouteElements.js';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './styles/global.scss';
import { useNavigate } from 'react-router-dom';
import UserContext from './contexts/UserContext';
import AddressContext from './contexts/AddressContext';
import BloodsGroupContext from './contexts/BloodsGroupContext';
import OrderTypeContext from './contexts/OrderTypeContext';
import ShipMethodContext from './contexts/ShipMethodContext';
import HospitalReceptionContext from './contexts/HospitalReceptionContext';
import userReducer from './reducers/userReducer';
import addressReducer, { FETCH_ADDRESSES_SUCCESS } from './reducers/addressReducer';
import bloodsGroupReducer, { FETCH_BLOODS_GROUP_SUCCESS } from './reducers/bloodsGroupReducer';
import orderTypeReducer, { FETCH_ORDER_TYPE_SUCCESS, FETCH_ORDER_TYPE_FAILURE } from './reducers/orderTypeReducer';
import shipMethodReducer, { FETCH_SHIP_METHOD_SUCCESS, FETCH_SHIP_METHOD_FAILURE } from './reducers/shipMethodReducer';
import hospitalReceptionReducer, {
   FETCH_HOSPITAL_RECEPTION_SUCCESS,
   FETCH_HOSPITAL_RECEPTION_FAILURE
} from './reducers/hospitalReceptionReducer';
import { axiosAuthAPI } from './configs/api.js';
import { endpoints } from './configs/endpoints.js';
import { provinces } from './mockdata/provinces.js';

function App() {
   const [user, dispatchUser] = useReducer(
      userReducer,
      Cookies.get('current-user') ? JSON.parse(Cookies.get('current-user')) : null
   );

   const [addresses, dispatchAddresses] = useReducer(addressReducer, []);
   const [bloodsGroup, dispatchBloodsGroup] = useReducer(bloodsGroupReducer, []);
   const [orderType, dispatchOrderType] = useReducer(orderTypeReducer, []);
   const [shipMethod, dispatchShipMethod] = useReducer(shipMethodReducer, []);
   const [hospitalReception, dispatchHospitalReception] = useReducer(hospitalReceptionReducer, {});

   const navigate = useNavigate();
   const routeElements = useRouteElements(user?.is_reception_hospital, user?.role.role, user?.id);
   const [fetchDataCompleted, setFetchDataCompleted] = useState(false);

   useEffect(() => {
      if (user === null) {
         navigate('/login');
      } else if (!fetchDataCompleted) {
         const fetchAllData = async () => {
            const initData1 = [{ id: 0, value: '', display: 'Tất cả nhóm máu' }];
            try {
               // Sử dụng Promise.all để gọi nhiều cuộc gọi API cùng một lúc
               const [resBloodsGroup, resOrderType, resShipMethod, resHospitalReception] = await Promise.all([
                  axiosAuthAPI().get(endpoints['list-bloods-group']),
                  axiosAuthAPI().get(endpoints['list-order-type']),
                  axiosAuthAPI().get(endpoints['list-ship-method']),
                  axiosAuthAPI().get(endpoints['get-hospital-reception'])
               ]);

               // Xử lý dữ liệu từ các cuộc gọi API ở đây và cập nhật state nếu cần
               const tempData1 = resBloodsGroup.data.list_data.map((item) => ({
                  id: item.id,
                  value: item.name_blood,
                  display: item.name_blood
               }));

               dispatchAddresses({ type: FETCH_ADDRESSES_SUCCESS, payload: provinces });
               dispatchBloodsGroup({
                  type: FETCH_BLOODS_GROUP_SUCCESS,
                  payload: initData1.concat(tempData1)
               });
               dispatchOrderType({ type: FETCH_ORDER_TYPE_SUCCESS, payload: resOrderType.data.list_data });
               dispatchShipMethod({ type: FETCH_SHIP_METHOD_SUCCESS, payload: resShipMethod.data.list_data });
               dispatchHospitalReception({
                  type: FETCH_HOSPITAL_RECEPTION_SUCCESS,
                  payload: resHospitalReception.data
               });
               setFetchDataCompleted(true);
            } catch (ex) {
               console.error(ex);
               dispatchAddresses({ type: FETCH_ADDRESSES_SUCCESS, payload: provinces });
               dispatchBloodsGroup({ type: FETCH_BLOODS_GROUP_SUCCESS, payload: initData1 });
               dispatchOrderType({ type: FETCH_ORDER_TYPE_FAILURE });
               dispatchShipMethod({ type: FETCH_SHIP_METHOD_FAILURE });
               dispatchHospitalReception({ type: FETCH_HOSPITAL_RECEPTION_FAILURE });
            }
         };
         fetchAllData();
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [user, fetchDataCompleted]);

   // console.log('user: ', user);
   // console.log('rerender');

   return (
      <UserContext.Provider value={[user, dispatchUser]}>
         <AddressContext.Provider value={[addresses, dispatchAddresses]}>
            <BloodsGroupContext.Provider value={[bloodsGroup, dispatchBloodsGroup]}>
               <OrderTypeContext.Provider value={[orderType, dispatchOrderType]}>
                  <ShipMethodContext.Provider value={[shipMethod, dispatchShipMethod]}>
                     <HospitalReceptionContext.Provider value={[hospitalReception, dispatchHospitalReception]}>
                        {routeElements}
                        <ToastContainer />
                     </HospitalReceptionContext.Provider>
                  </ShipMethodContext.Provider>
               </OrderTypeContext.Provider>
            </BloodsGroupContext.Provider>
         </AddressContext.Provider>
      </UserContext.Provider>
   );
}

export default App;
