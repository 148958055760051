import axios from 'axios';
import Cookies from 'js-cookie';
import { endpoints } from './endpoints';

export const axiosProvincesAPI = () =>
   axios.create({
      baseURL: process.env.REACT_APP_PROVINCES_OPEN_API
   });

export const axiosAPI = () =>
   axios.create({
      baseURL: process.env.REACT_APP_BASE_URL
   });

export const axiosAuthAPI = () => {
   const instance = axios.create({
      baseURL: process.env.REACT_APP_BASE_URL,
      headers: {
         Authorization: `Bearer ${Cookies.get('access-token')}`
      }
   });

   // Thêm interceptor cho Axios để xử lý việc refresh token
   instance.interceptors.response.use(
      (response) => {
         // Trả về phản hồi nếu không có lỗi
         return response;
      },
      async (error) => {
         // Kiểm tra nếu mã lỗi là 401 và không phải là yêu cầu refresh token ban đầu
         if (error.response.status === 401 && !error.config._retry) {
            error.config._retry = true; // Đánh dấu rằng yêu cầu đã được thử lại
            try {
               // Gửi yêu cầu refresh token
               const formData = new FormData();
               formData.append('refresh_token', Cookies.get('refresh-token'));
               formData.append('client_id', process.env.REACT_APP_CLIENT_ID);
               formData.append('client_secret', process.env.REACT_APP_CLIENT_SECRET);
               formData.append('grant_type', 'refresh_token');

               const refreshResponse = await axiosAPI().post(endpoints['login'], formData, {
                  headers: {
                     'Content-Type': 'multipart/form-data;'
                  }
               });
               // console.log('refreshResponse: ', refreshResponse);

               if (refreshResponse.status === 200) {
                  // Cập nhật token mới và refresh token (nếu có)
                  Cookies.set('access-token', refreshResponse.data.access_token);
                  if (refreshResponse.data.refresh_token) {
                     Cookies.set('refresh-token', refreshResponse.data.refresh_token);
                  }

                  // Cập nhật Authorization header trong Axios instance
                  error.config.headers['Authorization'] = `Bearer ${refreshResponse.data.access_token}`;

                  // Thực hiện lại yêu cầu gốc với access_token mới
                  return instance(error.config);
               }
            } catch (refreshError) {
               // Xử lý lỗi khi refresh token không thành công
               console.error('Refresh token failed: ', refreshError);
               // Đăng xuất người dùng
               // Trả về lỗi để có thể xử lý nó ở các component khác nếu cần
               return Promise.reject(refreshError);
            }
         }
         // Nếu không phải là lỗi 401 hoặc không thể refresh token, trả về lỗi gốc
         return Promise.reject(error);
      }
   );

   return instance;
};
