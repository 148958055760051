export const FETCH_HOSPITAL_RECEPTION_SUCCESS = 'FETCH_HOSPITAL_RECEPTION_SUCCESS';
export const FETCH_HOSPITAL_RECEPTION_FAILURE = 'FETCH_HOSPITAL_RECEPTION_FAILURE';

export default function hospitalReceptionReducer(state, action) {
   switch (action.type) {
      case FETCH_HOSPITAL_RECEPTION_SUCCESS:
         return action.payload;
      case FETCH_HOSPITAL_RECEPTION_FAILURE:
         return {};
      default:
         return state;
   }
}
