export const FETCH_SHIP_METHOD_SUCCESS = 'FETCH_SHIP_METHOD_SUCCESS';
export const FETCH_SHIP_METHOD_FAILURE = 'FETCH_SHIP_METHOD_FAILURE';

export default function shipMethodReducer(state, action) {
   switch (action.type) {
      case FETCH_SHIP_METHOD_SUCCESS:
         return action.payload;
      case FETCH_SHIP_METHOD_FAILURE:
         return [];
      default:
         return state;
   }
}
