export const FETCH_ADDRESSES_SUCCESS = 'FETCH_ADDRESSES_SUCCESS';
export const FETCH_ADDRESSES_FAILURE = 'FETCH_ADDRESSES_FAILURE';

export default function addressReducer(state, action) {
   switch (action.type) {
      case FETCH_ADDRESSES_SUCCESS:
         return action.payload;
      case FETCH_ADDRESSES_FAILURE:
         return [];
      default:
         return state;
   }
}
