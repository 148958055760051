export const endpoints = {
   //// users
   login: '/o/token/',
   register: '/users/',

   // Admin - User tự đổi
   'change-password': '/users/update-my-user/',

   'list-all-users-hospital': '/users/all-users-by-hospital/',
   'list-all-users': '/users/all-users/',
   'change-pass-first': '/users/check-change-password/',
   'current-user': '/users/current-user/',
   'create-hospital-user': '/users/new-all-user-bv/',
   'create-admin-user': '/users/register-admin-users/',

   // Admin - User tự đổi
   'update-user': `/users/update-my-user/`,

   // Admin đổi cho User
   'update-user-order': (id) => `/users/${id}/put/`,
   'update-status-user-order': (id) => `/users/${id}/ban-or-unban-user/`,

   //// bloodwarehouses
   'list-bloodwarehouses': '/bloodwarehouses/',
   'list-all-bloodwarehouses': '/bloodwarehouses/all-blood-warehouses/',
   'list-bloodwarehouses-order': '/bloodwarehouses/get-blood-ware-orders/',
   'create-bloodwarehouses': '/bloodwarehouses/create/',
   'create-init-bloodwarehouses': '/bloodwarehouses/init-blood-warehouses/',
   'update-bloodwarehouses': (id) => `/bloodwarehouses/${id}/update-amount/`,

   //// group-bloods
   'list-bloods-group': '/group-bloods/get/',
   'create-bloods-group': '/group-bloods/create/',
   'update-bloods-group': '/group-bloods/update/',

   //// hospitals
   'list-all-hospitals': '/hospitals/all/',
   'create-hospitals': '/hospitals/create/',
   'get-details-hospital': `/hospitals/details-hospital/`,
   'get-generate-code': '/hospitals/generate-code/',
   'get-hospital-reception': '/hospitals/get-infor-reception-hospital/',
   'update-hospital': (codeHosp) => `/hospitals/${codeHosp}/update-hospital/`,

   //// method-shipping
   'list-ship-method': '/method-shipping/get/',
   'create-ship-method': '/method-shipping/create/',
   'update-ship-method': (id) => `/method-shipping/${id}/update/`,

   //// order
   'accept-order': '/order/accept-order/',
   'create-order': '/order/add-1-order/',
   'cancel-editing-order': '/order/cancel-editing-order/',
   'cancel-order': '/order/cancel-order/',
   'editing-order': '/order/editing-order/',
   'merge-order': '/order/merge-list-choose-order/',
   'list-my-order-hosp': '/order/my-order-group-by-hospital/',
   'list-my-order': '/order/my-order/',
   'view-merge-order': '/order/order-view-merge/',

   're-order': '/order/re-order-reception/',
   'success-order': '/order/success-order/',
   'transporting-order': '/order/transporting-order/',
   'order-details': (id) => `/order/${id}/order-details/`,

   //// products
   'list-products': '/products/all/',
   'create-products': '/products/create/',
   'get-product-details': (id) => `/products/${id}/product-details/`,
   'update-product': (id) => `/products/${id}/update-product/`,

   //// role-user
   'list-role-user': '/role-user/get/',
   'create-role-user': '/role-user/create/',
   'update-role-user': (id) => `/role-user/${id}/update/`,

   //// status-order
   'list-status-order': '/status-order/',
   'create-status-order': '/status-order/create/',
   'update-status-order': '/status-order/update/',

   //// type-order
   'list-order-type': '/type-order/get/',
   'create-order-type': '/type-order/create/',
   'update-order-type': (id) => `/type-order/${id}/update/`,

   //// notify
   'update-read-all-notify': '/notify/update-read-all/',
   'update-read-notify': '/notify/update-read-notify/',
   'update-read-notify-reception': '/notify/update-read-notify-reception/',
   'reset-reload-notify': '/notify/update-reload/'
};

export const webEndpoints = {
   'get-addresses': (depth) => `/api/?depth=${depth}`
};
