import { lazy, Suspense } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import {
   faBoxesStacked,
   faCircleInfo,
   faDatabase,
   faHospital,
   faListUl,
   faSquarePlus,
   faUsers,
   faWarehouse
} from '@fortawesome/free-solid-svg-icons';
import Loading from './pages/Loading/Loading';
import LoadingSpinner from './shared/LoadingSpinner/LoadingSpinner';

const MainLayout = lazy(() => wait(1500).then(() => import('./layouts/MainLayout/MainLayout.jsx')));
const CreateOrder = lazy(() => wait(500).then(() => import('./components/CreateOrder/CreateOrder.jsx')));
const OrderManager = lazy(() => wait(500).then(() => import('./components/OrderManager/OrderManager.jsx')));
const OrderMerge = lazy(() => wait(500).then(() => import('./components/OrderMerge/OrderMerge.jsx')));
const WareHouseManager = lazy(() => wait(500).then(() => import('./components/WareHouseManager/WareHouseManager.jsx')));
const ProductManager = lazy(() => wait(500).then(() => import('./components/ProductManager/ProductManager.jsx')));
const HospitalManager = lazy(() => wait(500).then(() => import('./components/HospitalManager/HospitalManager.jsx')));
const EmployeeManager = lazy(() => wait(500).then(() => import('./components/EmployeeManager/EmployeeManager.jsx')));
const AppManager = lazy(() => wait(500).then(() => import('./components/AppManager/AppManager.jsx')));
const Account = lazy(() => wait(500).then(() => import('./components/Account/Account.jsx')));
const Login = lazy(() => import('./pages/Login/Login'));
const NotFound = lazy(() => import('./pages/NotFound/NotFound'));

const roles = {
   admin: 'Admin_BV',
   'staff-order': 'NV_BV',
   'staff-contact': 'LH_BV'
};

const features = [
   {
      icon: faSquarePlus,
      name: 'Tạo đơn đặt hàng',
      path: '/create-order',
      roles: ['bth-admin', 'bth-staff-order', 'hosp-admin', 'hosp-staff-order']
   },
   {
      icon: faListUl,
      name: 'Quản lý đơn hàng',
      path: '/order-manager',
      roles: [
         'bth-admin',
         'bth-staff-order',
         'bth-staff-contact',
         'hosp-admin',
         'hosp-staff-order',
         'hosp-staff-contact'
      ]
   },
   {
      icon: faWarehouse,
      name: 'Quản lý kho máu',
      path: '/warehouse-manager',
      roles: ['bth-admin', 'bth-staff-order', 'bth-staff-contact']
   },
   {
      icon: faBoxesStacked,
      name: 'Quản lý sản phẩm',
      path: '/product-manager',
      roles: ['bth-admin', 'bth-staff-order', 'bth-staff-contact']
   },
   {
      icon: faHospital,
      name: 'Quản lý bệnh viện',
      path: '/hospital-manager',
      roles: ['bth-admin']
   },

   {
      icon: faUsers,
      name: 'Quản lý nhân viên',
      path: '/employee-manager',
      roles: ['bth-admin', 'hosp-admin']
   },
   {
      icon: faDatabase,
      name: 'Quản lý danh mục',
      path: '/app-manager',
      roles: ['bth-admin']
   },
   {
      icon: faCircleInfo,
      name: 'Tài khoản và bệnh viện',
      path: '/account',
      roles: [
         'bth-admin',
         'bth-staff-order',
         'bth-staff-contact',
         'hosp-admin',
         'hosp-staff-order',
         'hosp-staff-contact'
      ]
   }
];

const childrenFeatures = [
   {
      path: '/create-order',
      roles: ['bth-admin', 'bth-staff-order']
   }
];

export default function useRouteElements(is_reception_hospital, role, userId = null) {
   const userRole = getUserRole(is_reception_hospital, role);
   const filteredFeatures = features.filter((feature) => feature.roles.includes(userRole));
   const routeElements = useRoutes([
      {
         path: '/',
         element: (
            <Suspense fallback={<Loading />}>
               <MainLayout features={filteredFeatures} userRole={userRole} />
            </Suspense>
         ),
         children: [
            {
               path: '',
               element: <Navigate to='/order-manager' />
            },
            {
               path: 'create-order',
               element: (
                  <Suspense fallback={<LoadingSpinner />}>
                     {hasAccess(features[0].roles, userRole) ? <CreateOrder /> : <Navigate to='/' replace />}
                  </Suspense>
               )
            },
            {
               path: 'order-manager',
               element: (
                  <Suspense fallback={<LoadingSpinner />}>
                     {hasAccess(features[1].roles, userRole) ? (
                        <OrderManager userRole={userRole} />
                     ) : (
                        <Navigate to='/' replace />
                     )}
                  </Suspense>
               )
            },
            {
               path: 'order-manager/order-merge',
               element: (
                  <Suspense fallback={<LoadingSpinner />}>
                     {hasAccess(childrenFeatures[0].roles, userRole) ? <OrderMerge /> : <Navigate to='/' replace />}
                  </Suspense>
               )
            },
            {
               path: 'warehouse-manager',
               element: (
                  <Suspense fallback={<LoadingSpinner />}>
                     {hasAccess(features[2].roles, userRole) ? (
                        <WareHouseManager userRole={userRole} />
                     ) : (
                        <Navigate to='/' replace />
                     )}
                  </Suspense>
               )
            },
            {
               path: 'product-manager',
               element: (
                  <Suspense fallback={<LoadingSpinner />}>
                     {hasAccess(features[3].roles, userRole) ? (
                        <ProductManager userRole={userRole} />
                     ) : (
                        <Navigate to='/' replace />
                     )}
                  </Suspense>
               )
            },
            {
               path: 'hospital-manager',
               element: (
                  <Suspense fallback={<LoadingSpinner />}>
                     {hasAccess(features[4].roles, userRole) ? <HospitalManager /> : <Navigate to='/' replace />}
                  </Suspense>
               )
            },
            {
               path: 'employee-manager',
               element: (
                  <Suspense fallback={<LoadingSpinner />}>
                     {hasAccess(features[5].roles, userRole) ? (
                        <EmployeeManager userRole={userRole} />
                     ) : (
                        <Navigate to='/' replace />
                     )}
                  </Suspense>
               )
            },
            {
               path: 'app-manager',
               element: (
                  <Suspense fallback={<LoadingSpinner />}>
                     {hasAccess(features[6].roles, userRole) ? <AppManager /> : <Navigate to='/' replace />}
                  </Suspense>
               )
            },
            {
               path: 'account',
               element: (
                  <Suspense fallback={<LoadingSpinner />}>
                     {hasAccess(features[7].roles, userRole) ? (
                        <Account userRole={userRole} />
                     ) : (
                        <Navigate to='/' replace />
                     )}
                  </Suspense>
               )
            }
         ]
      },
      {
         path: 'login',
         element: (
            <Suspense fallback={<Loading />}>{userId === null ? <Login /> : <Navigate to='/' replace />}</Suspense>
         )
      },
      {
         path: '*',
         element: (
            <Suspense fallback={<Loading />}>
               <NotFound />
            </Suspense>
         )
      }
   ]);

   return routeElements;
}

function getUserRole(is_reception_hospital, role) {
   if (is_reception_hospital) {
      switch (role) {
         case roles.admin:
            return 'bth-admin';
         case roles['staff-order']:
            return 'bth-staff-order';
         case roles['staff-contact']:
            return 'bth-staff-contact';
         default:
            return null;
      }
   }
   switch (role) {
      case roles.admin:
         return 'hosp-admin';
      case roles['staff-order']:
         return 'hosp-staff-order';
      case roles['staff-contact']:
         return 'hosp-staff-contact';
      default:
         return null;
   }
}

function hasAccess(requiredRole, userRole) {
   return requiredRole.includes(userRole);
}

function wait(time) {
   return new Promise((resolve) => {
      setTimeout(resolve, time);
   });
}
