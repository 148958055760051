export const FETCH_ORDER_TYPE_SUCCESS = 'FETCH_ORDER_TYPE_SUCCESS';
export const FETCH_ORDER_TYPE_FAILURE = 'FETCH_ORDER_TYPE_FAILURE';

export default function orderTypeReducer(state, action) {
   switch (action.type) {
      case FETCH_ORDER_TYPE_SUCCESS:
         return action.payload;
      case FETCH_ORDER_TYPE_FAILURE:
         return [];
      default:
         return state;
   }
}
