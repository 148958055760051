export const FETCH_BLOODS_GROUP_SUCCESS = 'FETCH_BLOODS_GROUP_SUCCESS';
export const FETCH_BLOODS_GROUP_FAILURE = 'FETCH_BLOODS_GROUP_FAILURE';

export default function bloodsGroupReducer(state, action) {
   switch (action.type) {
      case FETCH_BLOODS_GROUP_SUCCESS:
         return action.payload;
      case FETCH_BLOODS_GROUP_FAILURE:
         return [];
      default:
         return state;
   }
}
